'use client';
import { FC, useEffect, useState } from 'react';
import styles from './AuthPopup.module.scss';
import PopupContainer from '../PopupContainer';
import Image from 'next/image';
import Person from '../../../src/app/assets/icons/person.svg';
import Letter from '../../../src/app/assets/icons/letter2.svg';
import InputComponent from '../InputComponent';
import { AuthData } from '@/models/User.model';
import Link from 'next/link';
import OtpInput from 'react-otp-input';
import { useAppDispatch } from '@/app/redux/hooks';
import { sendCode, sendEmail } from '@/app/features/MainPage/mainSlice/MainActions';
import { VerifyCodeAnswer } from '@/models/Auth.model';
import { mainPath } from '@/app/routes/MainPath';
import { useParams, useRouter } from 'next/navigation';
import { PayloadAction } from '@reduxjs/toolkit';
import { validateEmail } from '@/helpers/functions';
import { useSearchParams } from 'next/navigation';
import ButtonElement from '../ButtonElement';
import { useCookies } from 'react-cookie';
import { useTranslations } from 'next-intl';
import {useLocalStorage} from "usehooks-ts";

interface AuthPopupProps {
  className?: string;
  setIsOpen: (arg: boolean) => void;
  isOpen: boolean;
  isPopup?: boolean;
  woRedirect?: boolean;
}

const AuthPopup: FC<AuthPopupProps> = ({
  className = '',
  setIsOpen,
  isOpen,
  isPopup,
  woRedirect = false,
}) => {
  const t = useTranslations('AUTH_POPUP');
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [step, setStep] = useState<number>(1);
  const { organization, slug } = useParams();
  const router = useRouter();
  const [codeError, setCodeError] = useState<boolean>(false);
  const [formData, setFormData] = useState<AuthData>({
    email: '',
    code: '',
  });
  const dispatch = useAppDispatch();
  const params = useSearchParams();
  const [token, setToken] = useState<string>('')

  useEffect(() => {
    setToken(localStorage.getItem('token') || '')
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (step === 2) {
      setStep(1);
    } else {
      setFormData((prevData) => ({ ...prevData, email }));
    }
  };

  const handleCodeChange = (e: any) => {
    setCodeError(false);
    setFormData(() => ({ ...formData, code: e }));
  };

  const quantity = params.get('quantity') || 1;
  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    if (step === 1) {
      dispatch(sendEmail(formData.email)).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setStep((prev) => prev + 1);
          setTime(120);
        }
      });
    }
    if (step === 2) {
      //RESEND
      formData.code.length < 6 &&
        time < 0 &&
        dispatch(sendEmail(formData.email)).then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setTime(120);
          }
        });
      //CONFIRM
      formData.code.length === 6 &&
        dispatch(sendCode(formData)).then(
          (
            res:
              | PayloadAction<VerifyCodeAnswer, string, { requestStatus: 'fulfilled' }, never>
              | PayloadAction<unknown, string, { requestStatus: 'rejected' }, unknown>,
          ) => {
            if (res.meta.requestStatus === 'fulfilled' && setIsOpen) {
              const { key } = res.payload as { key: string };
              localStorage.setItem('token', key);
              if (isPopup) {
                setIsOpen(false);
              } else {
                setIsOpen(false);
                woRedirect && setStep(1);
                organization &&
                  !woRedirect &&
                  slug &&
                  router.push(
                    `${mainPath.buy_ticket.path}/${organization}/${slug}`,
                  );
              }
            } else {
              setCodeError(true);
            }
          },
        );
    }
  };

  const isEmailValid = validateEmail(formData.email);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const [time, setTime] = useState<number>(120);

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClick(e);
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      //@ts-ignore
      setTime((prevTime: any) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <PopupContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      close={() => {
        setIsOpen(true);
        setStep(1);
        setFormData({
          email: '',
          code: '',
        });
      }}>
      <form className={`${styles.root} ${className}`}>
        {step === 1 && (
          <>
            <div className={styles.root__user}>
              <Image width={480} height={48} src={Person} alt="person" />
            </div>

            <h2 className={styles.root__title}>{t('login')}</h2>
            <p className={styles.root__subtitle}>{t('title')}</p>

            <div className={styles.root__form}>
              <InputComponent
                error={formData.email.length > 6 && !isEmailValid}
                type="email"
                autoComplete="email"
                className={`${styles.input} mt24`}
                label={t('email')}
                placeholder={'example@mail.com'}
                onChange={handleEmailChange}
              />

              <ButtonElement
                onClick={handleClick}
                type="button"
                text="Confirm"
                disabled={formData.email.length < 6}>
                {t('send')}
              </ButtonElement>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className={styles.root__user}>
              <Image width={480} height={48} src={Letter} alt="Letter" />
            </div>

            <h2 className={styles.root__title}> {t('check')}</h2>
            <p className={styles.root__subtitle}> {t('subtitle')}</p>

            <div className={styles.root__otp}>
              <OtpInput
                value={formData.code}
                onChange={(e) => handleCodeChange(e)}
                placeholder="●●●●●●"
                inputStyle={`${codeError && styles['root__otp--error']}`}
                numInputs={6}
                renderInput={(props) => <input {...props} onKeyUp={onKeyUp} />}
              />

              <ButtonElement
                onClick={handleClick}
                type="button"
                text="Confirm"
                disabled={time > 0 && formData.code.length !== 6}>
                {formData.code.length < 6 && time < 0 && t('resend')}
                {formData.code.length < 6 && time > 0 && formatTime(time)}
                {formData.code.length === 6 && t('confirm')}
              </ButtonElement>
            </div>
          </>
        )}

        <p className={styles.root__notion}>
          {t('by')} <Link href={"/public-offer"} target={"_blank"}>{t('public_offer')}</Link>
        </p>
      </form>
    </PopupContainer>
  );
};

export default AuthPopup;
