'use client';
import React, { FC, useState } from 'react';
import styles from './ButtonElement.module.scss';
import Link from 'next/link';

interface ButtonElementProps {
  className?: string;
  href?: string;
  color?: 'dark' | 'transperent' | 'link' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: (arg?: any) => void;
  blank?: boolean;
  prefetch?: boolean;
  text: string;
}

const ButtonElement: FC<ButtonElementProps> = ({
  className = '',
  href,
  color = 'default',
  size = 'medium',
  children,
  disabled = false,
  type = 'button',
  text,
  blank = false,
  prefetch = false,
  onClick,
}) => {
  const buttonClasses = `${styles.root} ${styles[color]} ${styles[size]} ${className}`;

  if (href) {
    return (
      <Link
        prefetch={prefetch}
        href={href}
        target={blank ? '_blank' : '_self'}
        className={buttonClasses}>
        {children}
      </Link>
    );
  } else {
    return (
      <button
        aria-label={text}
        name={text}
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={buttonClasses}>
        {children}
      </button>
    );
  }
};

export default ButtonElement;
